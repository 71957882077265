/* eslint-disable */
import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import RequireAuth from './Protected';
import { sidebarIcons } from 'layout/MainLayout/Sidebar/PageList/sidebarData';
// import CardDetail from 'views/cardDetail';
//import InsightDetailV2 from 'ui-component/insights/InsightDetailV2';
//import CommonView from 'views/common';
//import ManualInsightDetail from 'ui-component/insights/ManualInsight';
import { CpuSetting, Layer, Monitor } from 'iconsax-react';

const PageNotFound = Loadable(lazy(() => import('pages/no-page-found')));
const Analysis = Loadable(lazy(() => import('views/analysis')));
const Report = Loadable(lazy(() => import('views/report')));
const Help = Loadable(lazy(() => import('pages/help')));
const APITable = Loadable(lazy(() => import('views/admin/apps/linkage/linkageBlock/APITableContainer')));
const ViewAll = Loadable(lazy(() => import('views/admin/apps/linkage/viewAll/ViewAll')));
const ReportTracker = Loadable(lazy(() => import('views/report-tracker/indexTracker')));
const DataDisplay = Loadable(lazy(() => import('ui-component/decisionTracker/decision/DecisonData')));
const AskAsato = Loadable(lazy(() => import('air/AskAsato')));
// import AskAsato from 'air/AskAsato';
const IndexDecisionTracker = Loadable(lazy(() => import('ui-component/decisionTracker/history/indexDecisionHistory')));
const UpdateReport = Loadable(lazy(() => import('ui-component/report/selectedReport/selectedReport')));
const CreateLink = Loadable(lazy(() => import('views/link/Layout')));
const EditService = Loadable(lazy(() => import('views/link/EditService')));
const AssetView = Loadable(lazy(() => import('views/assets/AssetView')));
const CommonView = Loadable(lazy(() => import('views/common')));
const CardDetail = Loadable(lazy(() => import('views/cardDetail')));

const {
  Home: HomeIcon,
  Link: LinkIcon,
  Assets: AssetsIcon,
  Users: UsersIcon,
  Cost: CostIcon,
  Tracker: TrackerIcon,
  Access: AccessIcon,
  Report: ReportIcon,
  Ask: AskIcon
} = sidebarIcons;

const commonRoutes = [
  {
    path: '/home',
    name: 'Home',
    // todo : <CommonView /> section will be applied for wellstreet only - in future, this hardcoding will be removed
    element: <CommonView />,
    sidebar: true,
    svg: <HomeIcon />,
    subRoutes: [
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      }
    ],
    position: 'top',
    rolePermission: ['admin', 'executive', 'user']
  },
  {
    path: '/asset',
    name: 'asset',
    element: <CommonView />,
    sidebar: false,
    svg: <HomeIcon />,
    position: 'top',
    rolePermission: ['executive', 'user'],
    subRoutes: [
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['executive', 'user']
      }
    ]
  },
  {
    path: '/link',
    name: 'Link',
    element: <CommonView />,
    sidebar: true,
    svg: <LinkIcon />,
    position: 'top',
    rolePermission: ['admin'],
    subRoutes: [
      {
        path: '/detail',
        rolePermission: ['admin'],
        element: <CommonView />,
        subRoutes: []
      },
      {
        path: '/detail/:id',
        rolePermission: ['admin'],
        element: <CommonView />,
        subRoutes: []
      },
      {
        path: '/layout/:type',
        rolePermission: ['admin'],
        element: <CreateLink />,
        subRoutes: []
      },
      {
        path: '/layout/:type/:step',
        rolePermission: ['admin'],
        element: <CreateLink />,
        subRoutes: []
      },
      {
        path: '/layout/edit',
        rolePermission: ['admin'],
        element: <EditService />,
        subRoutes: []
      }
    ]
  },
  {
    path: '/apps',
    element: <CommonView />,
    rolePermission: ['admin', 'executive', 'user'],
    name: 'Apps',
    sidebar: true,
    position: 'top',
    svg: <AssetsIcon />,
    subRoutes: [
      {
        path: '/linkage-table',
        rolePermission: ['admin', 'executive', 'user'],
        element: <APITable />,
        subRoutes: []
      },
      {
        path: '/view-all',
        rolePermission: ['admin', 'executive', 'user'],
        element: <ViewAll />,
        subRoutes: []
      },
      {
        path: '/view-all/linkage-table',
        rolePermission: ['admin', 'executive', 'user'],
        element: <APITable />,
        subRoutes: []
      },
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      }
    ]
  },
  {
    path: '/hardware',
    element: <CommonView />,
    name: 'Hardware',
    sidebar: true,
    position: 'top',
    rolePermission: ['user', 'executive'],
    svg: <Monitor />,
    subRoutes: [
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['executive', 'user'],
        subRoutes: []
      }
    ]
  },
  {
    path: '/software',
    element: <CommonView />,
    name: 'Software',
    sidebar: true,
    position: 'top',
    rolePermission: ['user', 'executive'],
    svg: <CpuSetting />,
    subRoutes: [
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['executive', 'user'],
        subRoutes: []
      }
    ]
  },
  {
    path: '/usage',
    element: <CommonView />,
    name: 'Usage',
    sidebar: true,
    position: 'top',
    rolePermission: ['admin', 'executive', 'user'],
    svg: <UsersIcon />,
    subRoutes: [
      {
        path: '/linkage-table',
        element: <APITable />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      },
      {
        path: '/view-all',
        element: <ViewAll />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      },
      {
        path: '/view-all/linkage-table',
        rolePermission: ['admin', 'executive', 'user'],
        element: <APITable />,
        subRoutes: []
      },
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      }
    ]
  },
  {
    path: '/access',
    element: <Analysis />,
    name: 'Access',
    position: 'middle',
    rolePermission: ['admin'],
    subRoutes: [],
    sidebar: true,
    svg: <AccessIcon />
  },
  {
    path: '/cost',
    element: <CommonView />,
    name: 'Costs',
    rolePermission: ['admin', 'executive', 'user'],
    sidebar: true,
    position: 'top',
    svg: <CostIcon />,
    subRoutes: [
      {
        path: '/linkage-table',
        element: <APITable />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      },
      {
        path: '/view-all',
        element: <ViewAll />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      },
      {
        path: '/view-all/linkage-table',
        rolePermission: ['admin', 'executive', 'user'],
        element: <APITable />,
        subRoutes: []
      },
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['admin', 'executive', 'user'],
        subRoutes: []
      }
    ]
  },
  {
    path: '/report-tracker',
    element: <ReportTracker />,
    rolePermission: ['user', 'executive'],
    name: 'Tracker',
    sidebar: true,
    position: 'middle',
    svg: <TrackerIcon />,
    subRoutes: [
      {
        path: '/history',
        rolePermission: ['user', 'executive'],
        element: <IndexDecisionTracker />
      },
      {
        path: '/decision/:id',
        rolePermission: ['user', 'executive'],
        element: <DataDisplay />
      },
      {
        path: '/insight/:id',
        rolePermission: ['user', 'executive'],
        element: <CommonView />
      },
      {
        path: '/insight-v2/:id/asset-view',
        rolePermission: ['user', 'executive'],
        element: <AssetView />
      },
      {
        path: '/overview',
        element: <CardDetail />,
        rolePermission: ['executive', 'user']
      }
    ]
  },
  {
    path: '/insight/:id',
    rolePermission: ['user', 'executive'],
    element: <CommonView />,
    name: 'Insight',
    sidebar: false
  },
  {
    path: '/report',
    name: 'Report',
    element: <Report />,
    sidebar: true,
    rolePermission: ['user'],
    position: 'middle',
    svg: <ReportIcon />,
    subRoutes: [
      {
        path: '/:id',
        element: <UpdateReport />,
        rolePermission: ['user'],
        subRoutes: []
      }
    ]
  },
  {
    path: '/help',
    element: <Help />,
    rolePermission: ['admin', 'executive', 'user'],
    subRoutes: []
  },
  {
    path: '/ask-asato',
    rolePermission: ['executive', 'user'],
    element: <AskAsato />,
    subRoutes: [
      {
        path: '/response',
        rolePermission: ['executive', 'user'],
        element: <AskAsato />,
        subRoutes: [],
        name: 'Ask Asato'
      }
    ],
    name: 'Ask Asato',
    sidebar: true,
    position: 'bottom',
    svg: <AskIcon />
  },
  {
    path: '/datasets',
    rolePermission: ['executive', 'user'],
    element: <CommonView />,
    subRoutes: [
      {
        path: '/:id',
        rolePermission: ['executive', 'user'],
        element: <CommonView />,
        name: 'Dataset Details'
      }
    ],
    name: 'Datasets',
    sidebar: true,
    position: 'middle',
    svg: <Layer />
  }
];
let configRoute;

try {
  configRoute = JSON.parse(localStorage.getItem('configRoutes'));
} catch (error) {
  configRoute = null;
}

const finialisedRoute = (routes = commonRoutes, baseUrl = '') => {
  let finalRoute = [];

  if (!configRoute) {
    localStorage.clear();
    return [];
  }

  routes &&
    routes?.forEach((route) => {
      const path = baseUrl + route.path;
      const persona = localStorage.getItem('persona');

      const configRouteIndex = configRoute ? configRoute?.findIndex((item) => item.path === path) : -1;

      if (persona && configRouteIndex !== -1 && configRoute[configRouteIndex].rolePermission.includes(persona)) {
        finalRoute.push({
          path: path,
          element: route.element,
          svg: route.svg,
          name: route.name,
          position: route.position,
          icon: route.icon,
          sidebar: route.sidebar
        });
      }

      if (route?.subRoutes && route?.subRoutes?.length) {
        const subRoute = finialisedRoute(route.subRoutes, route.path);
        finalRoute = [...finalRoute, ...subRoute];
      }
    });

  return finalRoute;
};

const finalRoutes = finialisedRoute(commonRoutes);

finalRoutes.push({
  path: '*',
  rolePermission: ['admin', 'executive', 'user'],
  element: <PageNotFound />,
  subRoutes: []
});

const MainRoutes = {
  path: '/',
  element: (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  ),
  children: finalRoutes
};

export const allRoutes = finalRoutes;

export default MainRoutes;
